import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { baseUrl } from './environment';

const data = await axios.get(baseUrl).then(res => res.data);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>API Base URL: {baseUrl}</p>
        <p>Data persisted!</p>
        <span>New ID: {data.id}</span>
        <span>Content: {data.content}</span>
        <span>Created At: {data.createdAt}</span>
      </header>
    </div>
  );
}

export default App;
